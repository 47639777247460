import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { graphql } from 'gatsby'
import Prism from 'prismjs'

import csharp from 'prismjs/components/prism-csharp'
import swift from 'prismjs/components/prism-swift'
import kotlin from 'prismjs/components/prism-kotlin'
import c from 'prismjs/components/prism-c'

import '../styles/modules/mobile.scss'
import '../styles/modules/code-tabs.scss'
import mobileDiagram from '../assets/images/mobile/mobile-diagram.png'
import swiftSvgLogo from '../assets/images/mobile/swift-logo.svg'
import androidSvgLogo from '../assets/images/mobile/android-logo.svg'
import dotnetSvgLogo from '../assets/images/mobile/dotnet-logo.svg'

import VideoTiles from '../videos/components/VideoTiles'
import VideoTilesLoader from '../components/loaders/VideoTilesLoader'
import TutorialTiles from '../tutorials/components/TutorialTiles'

const Mobile = ({ data }) => {
  //TODO: run this useEffect each time user switches tab
  const [currentTab, setCurrentTab] = useState(0)
  useEffect(() => {
    Prism.highlightAll([csharp, swift, kotlin, c])
  }, [currentTab])
  const [videoData, setVideoData] = useState([])
  const tutorials = data.tutorials.nodes

  // Initial Effect that runs on page load
  useEffect(() => {
    const fetchVideos = async () => {
      let fetchUrl = `${process.env.GATSBY_API_URL}/v1/tags/videos?page=0&pageSize=9&tags=Couchbase%20Lite`
      const result = await fetch(fetchUrl, { method: 'get', mode: 'cors' })
      const data = await result.json()
      setVideoData(data)
    }
    fetchVideos()
  }, [])

  const handleTabFocus = (index) => {
    setCurrentTab(index)
  }

  return (
    <div className='page--mobile flex flex-col'>
      <Helmet title={`Mobile | Couchbase Developer Portal`} />

      <div className='wrapper pt-12 sm:pt-20 pb-8 sm:pb-24 grid grid-cols-1 sm:grid-cols-2 gap-12 place-items-center'>
        <div className='order-last sm:order-first'>
          <h2>Couchbase Mobile</h2>
          <p>Couchbase Mobile securely syncs data from any cloud to the
            edge, enabling you to store, query, search, and analyze data in
            the cloud, at the edge, or on the device regardless of internet
            connectivity and speed, guaranteeing that applications are always
            fast and always on.</p>
          <p>The latest release strengthens our support for edge computing by expanding our edge device platform support
            for C, Kotlin, and SQL++ in addition to secure Sync Gateway admin over the web.</p>
          <a href='https://www.couchbase.com/products/mobile/whats-new' target='_blank' rel='noopener noreferrer'
             alt='see whats new' className='cb-black-button-large'>See What's New</a>
        </div>
        <div>
          <img src={mobileDiagram} alt='mobile diagram' className='float-right' />
        </div>
      </div>

      <div className='appdev-red flex flex-row w-full justify-center text-white py-6'>
        <div className='appdev-section flex justify-end mt-6 mb-4 grid grid-cols-1 sm:grid-cols-2'>
          <div
            className='grid grid-cols-3 place-items-center float-left px-8 sm:px-0 mr-auto ml-auto sm:ml-0 sm:mr-16 gap-16 sm:gap-6 '>
            <a href='/learn/swift' target='_blank' rel='noopener noreferrer'
               className='h-2/3 relative hover:opacity-50'>
              <img src={swiftSvgLogo} alt='swift logo' height={100} width={100} className='' />
              <div className='absolute text-center bottom-2 w-full hidden lg:block'>
                Learn More
              </div>
            </a>
            <a href='/learn/android-kotlin-app-services' target='_blank' rel='noopener noreferrer'
               className='h-2/3 relative hover:opacity-50'>
              <img src={androidSvgLogo} alt='android logo' height={100} width={100} />
              <div className='absolute text-center bottom-2 w-full hidden lg:block'>
                Learn More
              </div>
            </a>
            <a href='/learn/xamarin' target='_blank' rel='noopener noreferrer'
               className='h-2/3 relative hover:opacity-50'>
              <img src={dotnetSvgLogo} alt='.net logo' height={100} width={100} className='mt-10' />
              <div className='absolute text-center bottom-2 w-full hidden lg:block'>
                Learn More
              </div>
            </a>
          </div>
          <div className='mt-8 sm:mt-0'>
            <h2 className='text-right'>Learning Couchbase Mobile</h2>
            <p className='text-right'>Learn how you can quickly add the Couchbase Lite SDK to your
              mobile app and enable features like query, indexes, and data sync.</p>
            <a href='/learn' className='cb-black-button float-right mt-4'>View Learning Paths</a>
          </div>
        </div>
      </div>

      <div className='py-12 wrapper'>
        <div className='min-codetabs'>
          <h2 className='py-6 text-center'>What does developing with Couchbase Mobile look like?</h2>
          <Tabs onSelect={handleTabFocus}>
            <TabList>
              <Tab>
                <p>iOS / Swift</p>
              </Tab>
              <Tab>
                <p>.NET / C#</p>
              </Tab>
              <Tab>
                <p>Android / Kotlin</p>
              </Tab>
              <Tab>
                <p>C</p>
              </Tab>
            </TabList>
            <TabPanel>
              <div className='panel-content'>
                <pre className='language-swift codeblock'>
                  <code>
                    {
                      `// Create or open a database
  let database: Database
  do {
     database = try Database(name: "mydb")
  } catch {
     fatalError("Error opening database")
  }
  
  // Create a new document (i.e. a record) in the database.
  let mutableDoc = MutableDocument()
     .setFloat(2.0, forKey: "version")
     .setString("SDK", forKey: "type")
  
  // Save it to the database.
  do {
     try database.saveDocument(mutableDoc)
  } catch {
     fatalError("Error saving document")
  }
  
  // Update a document.
  if let mutableDoc = database
                      .document(withID: mutableDoc.id)
                      ?.toMutable() {
     mutableDoc.setString("Swift", forKey: "language")
     do {
         try database.saveDocument(mutableDoc)
         let document = database.document(withID: mutableDoc.id)!
     } catch {
         fatalError("Error updating document")
     }
  }
  
  // Create a query to fetch documents of type SDK.
  let query = QueryBuilder
     .select(SelectResult.all())
     .from(DataSource.database(database))
    .where(Expression.property("type").equalTo(Expression.string("SDK")))
  
  // Run the query.
  do {
     let result = try query.execute()
     print("Number of rows :: \\(result.allResults().count)")
  } catch {
     fatalError("Error running the query")
  }
  
  // Create replicators to push and pull changes to and from the cloud.
  let targetEndpoint = 
    URLEndpoint(url: URL(string: "ws://localhost:4984/getting-started-db")!)
  var replConfig = ReplicatorConfiguration(database: database, target: targetEndpoint)
  replConfig.replicatorType = .pushAndPull
  
  // Add authentication.
  replConfig.authenticator = BasicAuthenticator(username: "john", password: "pass")
  
  // Create replicator 
  // (make sure to add an instance or static variable named replicator)
  let replicator = Replicator(config: replConfig)
  
  // Optionally,Listen to replicator change events.
  replicator.addChangeListener { (change) in
  if let error = change.status.error as NSError? {
         print("Error code :: \\(error.code)")
       }
  }
  
  // Start replication.
  replicator.start()
   `}
                  </code>
                </pre>
              </div>
            </TabPanel>
            <TabPanel>
              <div className='panel-content'>
                <pre className='language-csharp codeblock'>
                  <code>
                    {
                      `// Create or open database
  var database = new Database("mydb");
  
  // Create a new document (i.e. a record) in the database
  string id = null;
  using (var mutableDoc = new MutableDocument())
  {
     mutableDoc.SetFloat("version", 2.0f)
         .SetString("type", "SDK");
  
     database.Save(mutableDoc);
     id = mutableDoc.Id;
  }
  
  // Update a document
  using (var doc = database.GetDocument(id))
  using (var mutableDoc = doc.ToMutable())
  {
     mutableDoc.SetString("language", "C#");
     database.Save(mutableDoc);
     using (var docAgain = database.GetDocument(id))
     {
         Console.WriteLine($"Document ID :: {docAgain.Id}");
         Console.WriteLine($"Learning {docAgain.GetString("language")}");
     }
  }
  
  // Create a query to fetch documents of type SDK
  using (var query = QueryBuilder.Select(SelectResult.All())
     .From(DataSource.Database(database))
     .Where(Expression.Property("type").EqualTo(Expression.String("SDK"))))
  {
     // Run the query
     var result = query.Execute();
     Console.WriteLine($"Number of rows :: {result.AllResults().Count}");
  }
  
  // Create replicator to push and pull changes to and from the cloud
  var targetEndpoint = new URLEndpoint(new Uri("ws://localhost:4984/getting-started-db"));
  var replConfig = new ReplicatorConfiguration(database, targetEndpoint);
  
  // Add authentication
  replConfig.Authenticator = new BasicAuthenticator("john", "pass");
  
  // Optionally, Create replicator (make sure to add an instance or static variable
  // named _Replicator)
  var _Replicator = new Replicator(replConfig);
  _Replicator.AddChangeListener((sender, args) =>
  {
     if (args.Status.Error != null)
     {
         Console.WriteLine($"Error :: {args.Status.Error}");
     }
  });
  
  // Start replicator
  _Replicator.Start();
   `}
                  </code>
                </pre>
              </div>
            </TabPanel>
            <TabPanel>
              <div className='panel-content'>
                <pre className='language-kotlin codeblock'>
                  <code>
                    {
                      `// Create or open a database
  val cfg = DatabaseConfigurationFactory.create()
  val database = Database("mydb", cfg)
  
  // Create a new document (i.e. a record) in the database.
  var mutableDoc = MutableDocument().setFloat("version", 2.0f).setString("type", "SDK")
  
  // Save it to the database.
  database.save(mutableDoc)
  
  // Retrieve and update a document.
  mutableDoc = database.getDocument(mutableDoc.id)!!.toMutable().setString("language", "Java")
  database.save(mutableDoc)
  
  // Retrieve immutable document and log the document ID generated 
  // by the database and some document properties
  val document = database.getDocument(mutableDoc.id)!!
  
  // Create a query to fetch documents of type SDK.
  val rs = QueryBuilder.select(SelectResult.all())
    .from(DataSource.database(database))
    .where(Expression.property("type").equalTo(Expression.string("SDK")))
    .execute()
  
  // Create a replicator to push and pull changes to and from the cloud.
  val replicator = Replicator(
    ReplicatorConfigurationFactory.create(
        database = database,
        target = URLEndpoint(URI("ws://localhost:4984/getting-started-db")),
        type = ReplicatorType.PUSH_AND_PULL,
        authenticator = BasicAuthenticator("sync-gateway", "password".toCharArray())
    )
  )
  
  // Optional, Listen to replicator change events.
  replicator.addChangeListener { change ->
    val err = change.status.error
    if (err != null) {
        Log.i(TAG, "Error code ::  \${err.code}")
    }
  }
  
  // Start replication.
  replicator.start()
   `}
                  </code>
                </pre>
              </div>
            </TabPanel>
            <TabPanel>
              <div className='panel-content'>
                <pre className='language-c codeblock'>
                  <code>
                    {
                      `// Open or create the database
  CBLError err;
  CBLDatabase* database = CBLDatabase_Open(FLSTR("mydb"), NULL, &err);
  if(!database) {
     // Error handling.  For brevity, this is truncated in the rest of the snippet
     fprintf(stderr, "Error opening database (%d / %d)\\n", err.domain, err.code);
     FLSliceResult msg = CBLError_Message(&err);
     fprintf(stderr, "%.*s\\n", (int)msg.size, (const char *)msg.buf);
     FLSliceResult_Release(msg);
     return;
  }
  
  // Create a new document (i.e. a record) in the database
  CBLDocument* mutableDoc = CBLDocument_Create();
  FLMutableDict properties = CBLDocument_MutableProperties(mutableDoc);
  FLMutableDict_SetFloat(properties, FLSTR("version"), 3.0f);
  
  // Save it to the database
  if(!CBLDatabase_SaveDocument(database, mutableDoc, &err)) {
     // Failed to save, do error handling as above
     return;
  }
  FLStringResult id = FLSlice_Copy(CBLDocument_ID(mutableDoc));
  
  // Update a document
  mutableDoc = CBLDatabase_GetMutableDocument(database, FLSliceResult_AsSlice(id), &err);
  if(!mutableDoc) {
     return;
  }
  properties = CBLDocument_MutableProperties(mutableDoc);
  FLMutableDict_SetString(properties, FLSTR("language"), FLSTR("C"));
  if(!CBLDatabase_SaveDocument(database, mutableDoc, &err)) {
     return;
  }
  const CBLDocument* docAgain = CBLDatabase_GetDocument(database, 
    FLSliceResult_AsSlice(id), &err);
  if(!docAgain) {
       return;
  }
  FLString retrievedID = CBLDocument_ID(docAgain);
  FLDict retrievedProperties = CBLDocument_Properties(docAgain);
  FLString retrievedLanguage = 
    FLValue_AsString(FLDict_Get(retrievedProperties, FLSTR("language")));
  printf("Document ID :: %.*s\\n", (int)retrievedID.size, (const char *)retrievedID.buf);
  CBLDocument_Release(mutableDoc);
  CBLDocument_Release(docAgain);
  FLSliceResult_Release(id);
  
  // Create a query to fetch documents of type SDK
  int errorPos;
  CBLQuery* query = CBLDatabase_CreateQuery(database, kCBLN1QLLanguage, 
    FLSTR("SELECT * FROM _ WHERE type = \\"SDK\\""), &errorPos, &err);
  if(!query) {
     return;
  }
  CBLResultSet* result = CBLQuery_Execute(query, &err);
  if(!result) {
     return;
  }
  CBLResultSet_Release(result);
  CBLQuery_Release(query);
  
  // Create replicator to push and pull changes to and from the cloud
  CBLEndpoint* targetEndpoint = 
    CBLEndpoint_CreateWithURL(FLSTR("ws://localhost:4984/getting-started-db"), &err);
  if(!targetEndpoint) {
     // Failed to create endpoint, do error handling as above
     return;
  }
  CBLReplicatorConfiguration replConfig;
  CBLAuthenticator* basicAuth = CBLAuth_CreatePassword(FLSTR("john"), FLSTR("pass"));
  memset(&replConfig, 0, sizeof(replConfig));
  replConfig.database = database;
  replConfig.endpoint = targetEndpoint;
  replConfig.authenticator = basicAuth;
  CBLReplicator* replicator = CBLReplicator_Create(&replConfig, &err);
  CBLAuth_Free(basicAuth);
  CBLEndpoint_Free(targetEndpoint);
  if(!replicator) {
     // Failed to create replicator, do error handling as above
     return;
  }
  
  // Optionally, add replication listener
  CBLListenerToken* token = 
    CBLReplicator_AddChangeListener(replicator, getting_started_change_listener, NULL);
  
  // Start the replicator
  CBLReplicator_Start(replicator, false);
   `}
                  </code>
                </pre>
              </div>
            </TabPanel>
          </Tabs>
        </div>
        <a href='https://docs.couchbase.com/couchbase-lite/current/index.html' target='_blank' rel='noopener noreferrer'
           className='inline-block float-right text-sm sm:text-base pt-2 mr-6 sm:mr-4 lg:mr-2 btn-arrow-right hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Couchbase
          Lite Docs</a>
      </div>

      <div className='bg-gray-230'>
        <div className='py-12 wrapper mx-auto text-center'>
          <h2>Products</h2>
          <p>Couchbase Mobile is developer-friendly and can run anywhere – in the cloud, at the edge, and directly on
            mobile and edge devices
            – with automatic synchronization to ensure low latency, data integrity, and high availability for
            applications. It's a complete NoSQL
            database solution for all data storage, access, sync, and security from the cloud to the edge to the
            device.</p>
          <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 py-4'>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Couchbase Lite</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Couchbase Lite, our embedded database,
                manages and stores data locally on the
                device. It has full CRUD and SQL++
                query functionality, and supports all
                major platforms including iOS, OS X,
                tvOS, Android, Linux, Windows, Xamarin,
                Kotlin, Ionic, and more.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://www.couchbase.com/products/lite/' alt='couchbase lite' target='_blank'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Sync Gateway</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Couchbase Mobile includes synchronization
                between Couchbase Lite and Couchbase
                Server, and peer-to-peer synchronization
                between Couchbase Lite instances.
                Synchronization is orchestrated by Sync
                Gateway, our secure gateway.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://www.couchbase.com/products/sync-gateway' alt='sync gateway' target='_blank'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Couchbase Server</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Couchbase Server, our database server,
                manages and stores data in the cloud. It
                scales easily to billions of records and
                terabytes of data, supports millions of
                concurrent users, and provides 24x365
                uptime.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://www.couchbase.com/products/server' alt='couchbase server' target='_blank'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
          </div>
          <a href='https://www.couchbase.com/downloads' target='_blank' rel='noopener noreferrer' alt='downloads'
             className='cb-black-button-large'>Download Now</a>
        </div>
      </div>

      <div className='py-12 mb-6 wrapper text-center'>
        <h2>Platforms & Languages</h2>
        <p>Couchbase Mobile allows you to build amazing products and solutions using the platforms and languages you love.</p>
        <div className='grid grid-cols-1 sm:grid-cols-3 gap-12'>
          <div className='card-gray'>
            <h3 className='divider-below-center divider-dark'>iOS/iPadOS/MacOS</h3>
            <p className='text-left pb-10'>
              Learn how Couchbase Mobile can help you build robust
              applications in Swift or Objective-C with offline
              capabilities that can sync data from the cloud or your data center.
            </p>
            <div className='absolute bottom-6 left-0 w-full text-center'>
              <a href='/mobile/swift'
                 className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                More</a>
            </div>
          </div>
          <div className='card-gray'>
            <h3 className='divider-below-center divider-dark'>Android</h3>
            <p className='text-left pb-10'>
              Learn how Couchbase Mobile can help you build robust Android
              applications in Kotlin or Java with offline
              capabilities that can sync data from the cloud or your data center
            </p>
            <div className='absolute bottom-6 left-0 w-full text-center'>
              <a href='/mobile/android'
                 className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                More</a>
            </div>
          </div>
          <div className='card-gray'>
            <h3 className='divider-below-center divider-dark'>.NET</h3>
            <p className='text-left pb-10'>
              Learn how Couchbase Mobile can help you build robust
              .NET applications in C# with offline capabilities that
              can sync data from the cloud or your data center.
            </p>
            <div className='absolute bottom-6 left-0 w-full text-center'>
              <a href='/mobile/dotnet'
                 className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                More</a>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-gray-230'>
        <div className='py-12 wrapper mx-auto text-center'>
          <h2>Videos</h2>
          <p>Couchbase videos from all of our Couchbase events.</p>
          <div className='text-left w-11/12 mx-auto'>
            {videoData.length > 1
              ? <VideoTiles videos={videoData} numColumns={3} showDescription={false} />
              : <VideoTilesLoader
                colsXS={2} colsSM={2} colsMD={3} colsLG={4} colsXL={4} cols2XL={4}
              />
            }
          </div>
          <a href='/videos' className='cb-black-button-large'>More Videos</a>
        </div>
      </div>


      <div className='bg-white'>
        <div className='py-12 wrapper mx-auto text-center'>
          <h2>Tutorials</h2>
          <p>Learn to build and operate apps using Couchbase.</p>
          <div className='text-left'>
            <TutorialTiles tutorials={tutorials} showCategories={false} gridTilesContainerClass={'grid-tiles-container-blue-3col'} />
          </div>
          <a href='/tutorials' className='cb-black-button-large'>More Tutorials</a>
        </div>
      </div>


      <div className='bg-blue-400 w-full justify-center py-10 text-white'>
        <div className='text-center wrapper'>
          <h2>Other Resources</h2>
          <p>
            Couchbase Mobile is developer-friendly and can run anywhere – in the cloud, at the edge, and directly on
            mobile and edge devices
            – with automatic synchronization to ensure low latency, data integrity, and high availability for
            applications. It's a complete NoSQL
            database solution for all data storage, access, sync, and security from the cloud to the edge to the device.
          </p>
          <div className='grid grid-cols-1 sm:grid-cols-3 gap-6 my-8'>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Customer Case Studies</h3>
              <p className='text-left pb-10'>
                Enabling customer success with the
                world’s most powerful NoSQL database.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://www.couchbase.com/customers' target='_blank' alt='case studies'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Blog Articles</h3>
              <p className='text-left pb-10'>
                Blog posts about Couchbase Mobile,
                embedded databases, IoT, and Edge
                Computing.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://blog.couchbase.com/category/couchbase-mobile/?ref=blog-menu' target='_blank'
                   alt='blog articles' rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Documentation</h3>
              <p className='text-left pb-10'>
                Couchbase Mobile, Sync Gateway, and
                Couchbase Server SDK, API, and
                infrastructure documentation.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/home/mobile.html' target='_blank' alt='documentation'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Mobile


export const query = graphql`
  query tutorialsQueryMobile {
    tutorials: allMarkdownRemark(
      limit: 6
      filter: {frontmatter: {content_type: {in: ["tutorial", "quickstart"]}, filter: {eq: "mobile"}, landing_page: {eq: "mobile"}}}
      sort: {fields: [frontmatter___landing_order], order: ASC}
    ) {
      nodes {
        frontmatter {
          path
          content_type
          title
          technology
          filter
          description
          length
          tags
          landing_page
          landing_order
        }
        id
      }
    }
  }
`
